<template>
  <ValidationObserver @submit="assignSubjectHandler">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span class="flex gap-1">
          <span class="flex gap-1 rtl:flex-row-reverse text-xs md:text-base">
            <span v-i18n="dashboard">Enroll Teacher in</span>
            <span v-if="currentSubject">{{ currentSubject.title }}</span>
            <span v-i18n="dashboard">Subject</span>
          </span>
        </span>
      </template>
      <template v-slot>
        <InputFieldWrapper>
          <UiMultiSelectBox
            v-model="teacherIds"
            :options="enrolledTeacher"
            title="Teachers"
            label="full_name"
            class="flex-1"
            reduce="id"
            :already-selected="
              currentSubject && currentSubject.teachers ? currentSubject.teachers : []
            "
            :search-through-api="true"
            :image="true"
            @search="loadteacherList"
            @emitQuery="updateQuery"
          />
        </InputFieldWrapper>

        <InputFieldWrapper>
          <UiSingleSelect
            v-if="!currentSubject"
            v-model="subjectId"
            title="SUBJECTS"
            label="title"
            :options="enrolledSubjects"
            :search-through-api="true"
            class="flex-1"
            rules="required"
            reduce="id"
            @search="getSubjects"
          />
        </InputFieldWrapper>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary">
            <template v-if="isLoading">
              <Loader />
            </template>
            <template v-else>Save</template>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiMultiSelectBox from '@src/components/UiElements/UiMultiSelect.vue'
import { buildWhereQuery } from '@/src/utils/filters'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapState, mapActions } from 'vuex'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import filterMixins from '@/src/mixins/filter-mixins.js'
import { Form as ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationObserver,
    UiModalContainer,
    UiMultiSelectBox,
    UiSingleSelect,
    Loader,
    InputFieldWrapper,
    UIButton,
  },
  mixins: [filterMixins],
  props: {
    modal: { type: Boolean, default: false },
    classId: { type: String, default: '' },
    sectionId: { type: String, default: '' },
    currentSubject: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      alreadySelectedTeacherIds: [],
      $where: {},
      GENERAL_CONSTANTS,
      FILTER_KEYS,
      enrolledClasses: [],
      enrolledSubjects: [],
      dashboard: 'dashboard',
      enrolledTeacher: [],
      teacherIds: [],
      enrolSubject: {},
      subjectId: '',
      forceRenderTeachers: 0,
    }
  },
  computed: {
    ...mapState('layout', ['isLoading', 'currentCampusScope', 'currentSectionScope']),
  },
  watch: {
    enrolledTeacher: {
      handler() {
        this.forceRenderTeachers++
      },
    },
  },
  created() {
    if (this.currentSubject) this.enrolSubject = JSON.parse(JSON.stringify(this.currentSubject))
  },
  /**
   * Mounted Hook
   * @description Load subject when class is not selected by calling API
   */
  mounted() {
    this.enrolSubject.section_id = this.classId || this.currentSectionScope?.id
    if (!this.currentSectionScope?.id && !this.currentSubject) this.getSubjects()
    if (!this.currentSectionScope?.id && this.currentSubject) {
      this.sectionClasses({ class_id: this.currentSubject.class.id }).then((response) => {
        this.enrolledClasses = response.data
      })
    }
    this.loadteacherList()
  },
  methods: {
    receiveList(list) {},
    ...mapActions('section', ['sectionClasses']),
    ...mapActions('subjects', ['classSubjects', 'assignTeacherToSubject']),
    ...mapActions('teacher', ['getTeacherList', 'getTeacherWithFilters']),
    /**
     * Handle Click
     * @param {String} eventName
     * @returns {void}
     * @description Function Description :
     * - Handling click on the basis of parameter
     * - Emiting toggle to parent component
     */
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    /**
     * Load Teacher List
     * @description Function Description :
     * - Load campus base teacher to assign a subject of a class to a specific teacher
     * - Add full_name field to each object of teacher
     */
    loadteacherList(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
        },
      }
      this.getTeacherWithFilters(payload).then((response) => {
        this.enrolledTeacher = response?.data?.records
      })
    },
    /**
     * Load Subject
     * @description Loading subject from api response
     */
    getSubjects(query = '') {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
          ...buildWhereQuery(
            FILTER_KEYS.EQUAL,
            'class_id',
            this.classId || this.currentSectionScope?.id,
          ),
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_id', this.sectionId),
        },
      }
      this.classSubjects(payload).then((res) => {
        this.enrolledSubjects = res?.data?.records
      })
    },
    /**
     * Form Check
     * @param {Boolean} invalid
     * @returns {void}
     * @description Function Description :
     * - Checking form
     * - Emiting toggle to parent component
     */
    assignSubjectHandler() {
      this.$store.commit('layout/IS_LOADING', true)

      // Check if the current teacher is already enrolled
      const isCurrentTeacherAlreadyEnrolled = this.enrolledSubjects?.some((enrolledSubject) => {
        if (enrolledSubject.id !== this.subjectId) return false

        // Add unique teacher IDs to alreadySelectedTeacherIds
        enrolledSubject?.teachers?.forEach(({ id }) => {
          if (!this.alreadySelectedTeacherIds?.includes(id)) {
            this.alreadySelectedTeacherIds.push(id)
          }
        })

        // Check if any of the current teacher IDs are already enrolled
        return enrolledSubject.teachers.some(({ id }) => this.teacherIds?.includes(id))
      })

      if (isCurrentTeacherAlreadyEnrolled) {
        this.$store.commit('toast/NEW', { message: 'Teacher already enrolled', type: 'error' })
        this.$store.commit('layout/IS_LOADING', false)
        return
      }

      this.enrolSubject.teacher_ids = [...this.teacherIds, ...this.alreadySelectedTeacherIds]
      if (this.classId && this.sectionId) {
        this.enrolSubject.section_id = this.sectionId
        this.enrolSubject.id = this.subjectId
      }

      const payload = { ...this.enrolSubject }
      this.assignTeacherToSubject(payload).then(() => {
        this.$store.commit('layout/IS_LOADING', false)
        this.$emit('toggle', true)
        this.$store.commit('instituteCount/INCREMENT_INSTITUTE_COUNT', 'subjects_count')
      })
    },
  },
}
</script>
